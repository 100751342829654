<template>
  <div class="information abroadInfo">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="gotoFather">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">话题详情</span>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/topics' }">话题分析</el-breadcrumb-item>

      <el-breadcrumb-item><span class="breadcrumb-meaasge">/</span>信息详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div v-loading="loading" style="min-height: 495px">
      <div v-if="textData.isDel == 1" class="information-box">
        <div class="delete-wrap">
          <div class="delete-div">
            <div class="delete-img">
              <img src="../../assets/img/views/deleteWrap.png" alt />
              <div class="delete-x">X</div>
            </div>
            <p>当前数据已删除</p>
          </div>
        </div>
      </div>
      <!-- 外面的大盒子 -->
      <div v-if="textData.isDel != 1" class="information-box">
        <div class="informaton-main">
          <div class="main-top">
            <div class="main-top-left"></div>
            <!-- 中间部分 -->
            <div class="main-top-center">
              <div class="main-top-center-box">
                <div v-if="textData.isEarlyWarn == 1" class="yujing">已预警</div>
                <div class="yujing-box">
                  <p class="center-title" v-html="textData.title ? textData.title : '无'"></p>
                </div>
              </div>
              <div class="caozuo-center">
                <div class="type english">
                  {{ textData.languageType ? textData.languageType : "无" }}
                </div>
                <div class="type website">{{ textData.mediaType ? textData.mediaType : "无" }}</div>
                <a :href="textData.website" target="_blank" class="original original-color">
                  <span>
                    <img src="../../assets/img/views/wenzhang.png" alt />
                  </span>
                  <span>查看原文</span>
                </a>
                <div class="center-timer">
                  发布时间:
                  <span>
                    {{textData.releaseTime ? textData.releaseTime : "无"}}
                  </span>
                </div>
              </div>
              <div class="operation-box">
                <!-- 复制 -->
                <div class="operation-copy my-caozuo" @click="copy(textData.copy)">
                  <span>
                    <span>
                      <img src="../../assets/img/views/fuzhi.png" alt />
                    </span>
                  </span>
                  <span>复制全部</span>
                </div>
                <div @click="quickCopy(textData)" class="my-caozuo">
                  <span style="margin:0;color:black;font-size:16px" class="el-icon-link"></span>
                  复制链接和标题
                </div>
              </div>
            </div>
            <div class="main-top-right"></div>
          </div>
          <div style="padding: 24px 210px 0 210px">
            <!-- 中间青色位置 -->
            <div class="main-center">
              <div class="main-center-left">

                <div class="left-source">
                  来源/作者:
                  <span v-html="textData.source?textData.source:'无'"></span>
                  <span>/</span>
                  <span v-html="textData.sourceAuthor?textData.sourceAuthor:'无'"></span>
                  <span class="my-caozuo" @click="myCopy([textData.source, textData.sourceAuthor], 1)">
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </div>

                <div class="left-country">
                  归属-阵营:
                  {{(textData.mediaOwnership?textData.mediaOwnership:"无")+"-"+(textData.mediaCamp?textData.mediaCamp:"无")}}
                  <span class="my-caozuo" @click="myCopy([textData.mediaOwnership, textData.mediaCamp], 2)">
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </div>

                <div class="left-language">
                  关键字:
                  <span v-if="textData.keyword">
                    <span v-for="(ite, indx) in textData.keywordList" :class="[ite!=textData.keywordList[textData.keywordList.length-1]?'styleMargin':'']" :key="indx">
                      {{ ite }}
                    </span>
                  </span>
                  <span v-else>无</span>
                  <el-tooltip v-if="textData.keyword &&textData.keywordArry&& textData.keywordArry.length > 10" class="item" effect="dark" placement="top">
                    <div slot="content" v-html="textData.keyword"></div>
                    <span class="gengduo-link">(更多)</span>
                  </el-tooltip>
                  <span class="my-caozuo" @click="myCopy(textData.keyword, 3)">
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </div>

              </div>
              <div class="main-center-center">
                <div class="left-source">
                  媒体类型: {{ textData.mediaType ? textData.mediaType : "无" }}
                  <span class="my-caozuo" @click="myCopy(textData.mediaType, 4)">
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </div>
                <div class="left-source" style="margin-top:26px">
                  语言类型: {{ textData.languageType ? textData.languageType : "无" }}
                  <span class="my-caozuo" @click="myCopy(textData.languageType, 6)">
                    <img src="../../assets/img/views/hfz.png" alt />
                  </span>
                </div>
              </div>
            </div>
            <p v-html="item?item:''" class="main-text" v-for="(item, index) in textData.contentList" :key="index + 'a'"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getInfoKe} from "../../api/topic/topic";

export default {
  data() {
    return {
      loading: false,
      dataId: "",
      textData: {},
      fatherLink:{}
    };
  },
  methods: {
    // 所有复制
    copy() {
      let str = 
      `标题：${this.textData.title}
      链接：${this.textData.website}
      来源/作者：${this.textData.source?this.textData.source:"未知"+"/"+this.textData.sourceAuthor?this.textData.sourceAuthor:"未知"}
      媒体类型：${this.textData.mediaType?this.textData.mediaType:"无"}
      媒体阵营：${this.textData.mediaCamp?this.textData.mediaCamp:"无"}
      语言类型：${this.textData.languageType?this.textData.languageType:"无"}
      标题：${this.textData.source}`;
      this.$publicFun.copyFun(str);
    },
    //复制链接与标题
    quickCopy(msg){
      let str = "";
      str = "标题："+msg.title+"\n"+"链接："+msg.website;
      this.$publicFun.copyFun(str);
    },
    myCopy(msg, type) {
      let str = "";
      if (type == 2) {
        msg = msg.map(item => {
          return item ? item : "无";
        });
        str = `归属-阵营:${msg}`;
      }
      if (type == 1) {
        msg = msg.map(item => {
          return item ? item : "无";
        });
        str = `来源/作者:${msg}`;
      }
      msg = msg ? msg : "无";
      if (type == 3) {
        str = `关键字:${msg}`;
      } else if (type == 4) {
        str = `媒体类型:${msg}`;
      } else if (type == 5) {
        str = `舆情标签:${this.label}`;
      } else if (type == 6) {
        str = `语言类型:${msg}`;
      }
      this.$publicFun.copyFun(str);
    },
    gotoFather(){
      this.$router.push({
        path:"/topics/index/topicList/topicListDetails",
        query:{
          id:this.fatherLink.id,
          topicId:this.fatherLink.topicId,
          type:this.fatherLink.type,
          topicType:this.fatherLink.topicType,
          isDeepUpdate:this.fatherLink.isDeepUpdate,
          isPause:this.fatherLink.isPause,
        }
      });
    },
    // 获取列表
    async getList() {
      this.loading = true;
      let res = await getInfoKe({topicOverseasId:this.dataId});
      if (res.data.data) {
        this.textData = res.data.data;
        if (this.textData.keyword) {
          this.textData.keyword = this.textData.keyword.replace(/<br\/>/g," ");
          this.textData.keywordList = this.textData.keyword.split(" ");
          this.textData.keywordArry = this.textData.keyword.split(" ");
          if (this.textData.keywordList.length > 10) {
            this.textData.keywordList = this.textData.keywordList.slice(0, 10);
          }
          let reg = new RegExp(" ", "g"); //g代表全部
          this.textData.keyword = this.textData.keyword.replace(reg, "<br/>");
        }
        if (this.textData.content) {
          this.textData.contentList = this.textData.content.split("\n");
        }
      }
      this.loading = false;
    }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.dataId = params.dataId;
      this.fatherLink.id=params.id;
      this.fatherLink.topicId=params.topicId;
      this.fatherLink.type=params.index;
      this.fatherLink.topicType=params.topicType;
      this.fatherLink.isDeepUpdate=params.isDeepUpdate;
      this.fatherLink.isPause=params.isPause;
    }
    this.getList();
  },
};
</script>

<style scoped>
.information-haeder {
  justify-content: space-between;
  display: flex;
  padding: 10px 24px;
  width: 100%;
  height: 56px;
  background: #ffffff;
}

.styleMargin {
  margin-right: 5px;
}

.gengduo-link {
  font-size: 14px;
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.quan {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #cccccc;
  border-radius: 50%;
}

.main-top-center-box {
  display: flex;
  align-items: center;
}

.main-top-center-box .yujing {
  margin-left: 0;
  width: 62px;
  height: 24px;
  line-height: 24px;
  margin-right: 10px;
  padding: 0 8px;
  background: #ffedef;
  font-size: 14px;
  font-weight: bold;
  color: #ea3342;
}

.main-top-center-box .yujing-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.main-top-center-box div:nth-child(2) p {
  width: 100%;
}

.xiangq {
  margin-top: 4px;
  margin-left: 16px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.comment-weidu {
  color: #ea3342;
}

.information-box {
  padding: 0 24px 16px 24px;
}

.informaton-main {
  padding: 35px 32px 28px 35px;
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
}

.main-top {
  position: relative;
  padding: 40px 210px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e4e6ec;
}

.main-top-left {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 55px;
  background: url("../../assets/img/views/beijing.png");
  background-size: 37px 55px;
}

.main-top-right {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 166px;
  height: 180px;
  background: url("../../assets/img/views/beijing.png");
}

.center-title {
  line-height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.type {
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  padding: 0 8px;
}

.website {
  margin-left: 16px;
  background-color: #868bff;
}

.original {
  display: flex;
  margin-left: 16px;
  font-size: 14px;
}

.original-color {
  color: #2e59ec;
}

.original span:nth-child(1) {
  margin-top: 2px;
  margin-right: 5px;
}

.center-timer {
  margin-left: 24px;
  font-size: 14px;
  color: #999999;
}

.gengduo {
  padding-left: 8px;
  width: 46px;
  height: 20px;
  cursor: pointer;
  color: #2e59ec;
  font-size: 14px;
}

.main-text {
  margin-top: 24px;
  line-height: 26px;
  text-indent: 2em;
  font-size: 14px;
  color: #333333;
}

.caozuo-center {
  line-height: 24px;
  margin-top: 24px;
  display: flex;
}

.operation-box img {
  width: 16px;
  height: 16px;
}

.operation-box {
  margin-top: 18px;
  display: flex;
  width: 1137px;
  font-size: 14px;
  color: #333333;
  line-height: 20px;
  align-items: center;
}

.operation-box > div:not(:first-child) {
  margin-left: 40px;
}

.operation-box > div span:nth-child(1):nth-child(1) {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.operation-box > div span:nth-child(1) span {
  position: absolute;
  top: 3px;
  left: 0;
}

.main-center {
  padding: 24px 24px 24px 32px;
  display: flex;
  width: 100%;
  height: 156px;
  background: #f4f8fd;
}

.main-center > div {
  font-size: 14px;
  color: #333333;
  /* flex: 1; */
}

.main-center-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.main-center-center {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.left-source,
.left-country {
  display: flex;
  align-items: center;
}

.left-language {
  display: flex;
}

.left-language span,.left-source span,.left-country span {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.operation-copy {
  margin: 0;
}

.center-right-img {
  width: 111px;
  height: 108px;
}

.center-right-img img {
  width: 111px;
  height: 108px;
}

.main-center-right {
  height: 110px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: flex-end;
}

.english {
  background-color: #3e82ff;
}

.yidu {
  color: #02bc7c;
}

.yishoucang {
  color: #ff9000;
}

.gengduo-link {
  cursor: pointer;
  color: #2e59ec;
  margin-left: 5px;
}

.delete-wrap {
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  height: 700px;
  padding-top: 208px;
}

.delete-div {
  text-align: center;
}

.delete-div > p {
  font-size: 14px;
  color: #999999;
}

.delete-img {
  position: relative;
  width: 368px;
  margin: auto auto;
}

.delete-x {
  font-size: 70px;
  color: #ffb2b2;
  position: absolute;
  bottom: 10px;
  right: 120px;
  z-index: 22;
}
</style>
